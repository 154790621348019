<template>
  <div class="products-westudy">
    <WestudyHeadDesktop />

    <div class="container">
      <WestudyHelpDesktop />

      <WestudyPlatformDesktop />
    </div>

    <WestudyEducationDesktop />

    <div class="container">
      <WestudyMetodichkaDesktop />
      <WebstudyFundtionsDesktop />
    </div>

    <WestudyAnalyticsDesktop />

    <div class="container">
      <WestudyInvolvementDesktop />
    </div>

    <WestudyPromotionDesktop />

    <div class="container">
      <WestudyTariffsDesktop />

      <WestudyFormDesktop />
    </div>
  </div>
</template>

<script>
import WestudyHeadDesktop from "@/components/Desktop/Products/Westudy/WestudyHeadDesktop";
import WestudyHelpDesktop from "@/components/Desktop/Products/Westudy/WestudyHelpDesktop";
import WestudyPlatformDesktop from "@/components/Desktop/Products/Westudy/WestudyPlatformDesktop";
import WestudyEducationDesktop from "@/components/Desktop/Products/Westudy/WestudyEducationDesktop";
import WestudyMetodichkaDesktop from "@/components/Desktop/Products/Westudy/WestudyMetodichkaDesktop";
import WebstudyFundtionsDesktop from "@/components/Desktop/Products/Westudy/WestudyFunctionsDesktop";
import WestudyAnalyticsDesktop from "@/components/Desktop/Products/Westudy/WestudyAnalyticsDesktop";
import WestudyInvolvementDesktop from "@/components/Desktop/Products/Westudy/WestudyInvolvementDesktop";
import WestudyPromotionDesktop from "@/components/Desktop/Products/Westudy/WestudyPromotionDesktop";
import WestudyTariffsDesktop from "@/components/Desktop/Products/Westudy/WestudyTariffsDesktop";
import WestudyFormDesktop from "@/components/Desktop/Products/Westudy/WestudyFormDesktop";
export default {
  name: 'ProductsWestudyDesktop',
  components: {
    WestudyFormDesktop,
    WestudyTariffsDesktop,
    WestudyPromotionDesktop,
    WestudyInvolvementDesktop,
    WestudyAnalyticsDesktop,
    WebstudyFundtionsDesktop,
    WestudyEducationDesktop,
    WestudyMetodichkaDesktop,
    WestudyPlatformDesktop,
    WestudyHelpDesktop,
    WestudyHeadDesktop
  }
}
</script>

<style lang="scss" scoped>
.products-westudy {
  padding: 32px 0 128px;
  font-family: $font-mts-compact;
  color: $color-dark-2;

  & .container {
    max-width: 1344px;
    padding: 0 40px;
  }

  @include screen-down('lg') {
    padding-bottom: 80px;
  }
}
</style>
