// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/loader-mts.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tariff-form[data-v-4ffd9dff]{width:100%;border-radius:8px;background:#fff;padding:32px;font-family:MTS Compact,Arial,sans-serif;color:#1d2023;font-size:17px;line-height:24px;font-weight:400}.tariff-form__title[data-v-4ffd9dff]{font-size:24px;line-height:28px;font-weight:500}.tariff-form__description[data-v-4ffd9dff]{margin-bottom:16px}.tariff-form__form[data-v-4ffd9dff]{display:flex;flex-direction:column}.tariff-form__form-fields[data-v-4ffd9dff]{display:grid;grid-template-columns:1fr;grid-row-gap:16px;row-gap:16px}.tariff-form__btn[data-v-4ffd9dff]{width:100%;margin:16px auto}.tariff-form__info[data-v-4ffd9dff]{color:#626c77;font-size:12px;line-height:17px;max-width:322px;margin:0 auto;text-align:center}.tariff-form__info a[data-v-4ffd9dff]{color:#0070e5}.tariff-form .loading button.tariff-form__btn[data-v-4ffd9dff]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:rgba(188,195,208,.5);background-size:7%;background-repeat:no-repeat;background-position:50%;pointer-events:none}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
