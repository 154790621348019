<template>
  <div class="tariff-form">
    <form v-if="!statusForm" :id="paramsForForm.formName" :name="paramsForForm.formName" class="tariff-form__form"
      :class="{ 'loading': isLoading === true }" @submit="onSubmit" action="">
      <input :id="'formid_' + paramsForForm.formName" type="hidden" name="formid" hidden="hidden">
      <input :id="'fio_' + paramsForForm.formName" type="hidden" name="fio">
      <input :id="'google_cid_' + paramsForForm.formName" type="hidden" name="google_cid">
      <input :id="'locale_' + paramsForForm.formName" type="hidden" name="locale" value="ru">

      <input v-if="paramsForForm.employee" :id="'employee_' + paramsForForm.formName" type="hidden" name="employee"
        :value="paramsForForm.employee">
      <input v-if="paramsForForm.nameTariffs" :id="'tariffs_name_' + paramsForForm.formName" type="hidden"
        name="tariffs_name" :value="paramsForForm.nameTariffs">

      <input v-if="paramsForForm.employee" :id="'number_of_employees_' + paramsForForm.formName" type="hidden"
        name="number_of_employees" :value="numberOfEmployeesValue">
      <input :id="'question_' + paramsForForm.formName" type="hidden" name="question" :value="questionValue">


      <div class="tariff-form__form-fields">
        <h3 class="tariff-form__title">{{ title }}</h3>

        <p class="tariff-form__description">{{ description }}</p>

        <div class="tariff-form__field">
          <VInputMTS id="name" v-model="form.firstname" name="name" placeholder="Имя" autocomplete="name" type="text"
            :class="{ 'error': getErrors('firstname') && $v.form['firstname'].$dirty }"
            @focus="$v.form['firstname'].$reset()" />
          <VErrorMTS v-if="getErrors('firstname') && $v.form['firstname'].$dirty">{{ getErrors('firstname') }}
          </VErrorMTS>
        </div>

        <div class="tariff-form__field">
          <VInputMTS id="email" v-model="form.email" name="email" placeholder="E-mail" autocomplete="email" type="text"
            :class="{ 'error': getErrors('email') && $v.form['email'].$dirty }" @focus="$v.form['email'].$reset()" />
          <VErrorMTS v-if="getErrors('email') && $v.form['email'].$dirty">{{ getErrors('email') }}</VErrorMTS>
        </div>

        <div class="tariff-form__field">
          <VPhoneInputMTS v-model="form.phone" :class="{ 'error': getErrors('phone') && $v.form['phone'].$dirty }"
            @focus="$v.form['phone'].$reset()" />
          <VErrorMTS v-if="getErrors('phone') && $v.form['phone'].$dirty">{{ getErrors('phone') }}</VErrorMTS>
        </div>
      </div>

      <VButtonMTS type="submit" :class="['tariff-form__btn', '--violet', '--l']">Оставить заявку</VButtonMTS>
      <div class="tariff-form__info">
        Нажимая на кнопку «Оставить заявку», вы принимаете
        <a target="_blank" href="/legal/license-agreement.pdf">оферту</a>,
        соглашаетесь на <a target="_blank" href="/legal/private-agreement-soft.pdf">обработку персональных данных</a>
      </div>
    </form>
    <MTSFormMessage
      v-if="statusForm"
      :success="success"
      :type-message="statusFormText"
    />
  </div>
</template>

<script>
import SubmitGatewayMixin from "@/mixins/SubmitGatewayMixin";
import EventBus from "@/mixins/EventBus";
import ValidationFields from "@/mixins/ValidationFields";
import VPhoneInputMTS from "@/components/Common/MTS/VPhoneInputMTS";
import VInputMTS from "@/components/Common/MTS/VInputMTS";
import VErrorMTS from "@/components/Common/MTS/VErrorMTS";
import VButtonMTS from "@/components/Common/MTS/VButtonMTS"
import MTSFormMessage from "@/components/Common/Form/MTSFormMessage";

export default {
  name: 'MTSFormTariff',
  components: { MTSFormMessage, VInputMTS, VErrorMTS, VPhoneInputMTS, VButtonMTS },
  mixins: [ValidationFields, SubmitGatewayMixin, EventBus],
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    paramsForForm: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      form: {
        firstname: '',
        email: '',
        phone: '',
      },
      imgForMessage: {
        success: {
          src: require('~/assets/img/icons/tariffs/icon-mts--popup-success.svg'),
          width: '218',
          height: '160'
        },
        fail: {
          src: require('~/assets/img/icons/tariffs/icon-mts--popup-fail.svg'),
          width: '268',
          height: '179'
        }
      },
      abFormSelectorVariant: '0',
      abTestHiddenFields: {},
    };
  },
  computed: {
    numberOfEmployeesValue() {
      return this.paramsForForm.employee ? 'Компания, ' + this.paramsForForm.employee + ' сотрудников' : '';
    },
    questionValue() {
      if (!this.paramsForForm.nameTariffs && !this.paramsForForm.employee) {
        return '';
      }
      return 'Запрос на тариф: "' + (this.paramsForForm.nameTariffs ?? '') + '" Сотрудников: ' + (this.paramsForForm.employee ?? '');
    },
  },
  watch: {
    statusForm(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$emit('close-popup')
        }, 5000);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tariff-form {
  width: 100%;
  border-radius: 8px;
  background: $color-white;
  padding: 32px;
  font-family: $font-mts-compact;
  color: $color-dark-2;
  font-size: 17px;
  line-height: 24px;
  font-weight: 400;

  &__title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
  }

  &__description {
    margin-bottom: 16px;
  }

  &__form {
    display: flex;
    flex-direction: column;

    &-fields {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 16px;
    }
  }

  &__btn {
    width: 100%;
    margin: 16px auto;
  }

  &__info {
    color: $color-gray-1;
    font-size: 12px;
    line-height: 17px;
    max-width: 322px;
    margin: 0 auto;
    text-align: center;

    a {
      color: #0070E5;
    }
  }

  .loading button.tariff-form__btn {
    background-image: url("~/assets/img/loader-mts.svg");
    background-color: rgba(188, 195, 208, 0.5);
    background-size: 7%;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
  }
}
</style>
