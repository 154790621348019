<template>
  <div class="mts-application-form">
    <template v-if="!formSubmissionResult">
      <h2
        v-if="formTitle"
        class="mts-application-form__title"
        v-html="formTitle"
      ></h2>

      <div
        v-if="formDescription"
        class="mts-application-form__description"
        v-html="formDescription"
      ></div>

      <form
        :id="formName"
        :name="formName"
        :class="['mts-application-form__form', { loading: isLoading === true }]"
        @submit="onSubmit"
      >
        <input
          :id="`formId_${formName}`"
          type="hidden"
          name="formId"
          :value="formIdValue"
        />

        <input
          id="question"
          :value="formUserComment"
          type="hidden"
          name="question"
        />

        <input :id="`fio_${formName}`" type="hidden" name="fio" />
        <input :id="`google_cid_${formName}`" type="hidden" name="google_cid" />
        <input
          :id="`locale_${formName}`"
          type="hidden"
          name="locale"
          value="ru"
        />

        <div class="mts-application-form__form-fields">
          <div class="mts-application-form__field">
            <VInputMTS
              id="name"
              v-model="form.firstname"
              name="name"
              placeholder="Имя"
              autocomplete="name"
              type="text"
              :class="{
                error: getErrors('firstname') && $v.form['firstname'].$dirty,
              }"
              @focus="$v.form['firstname'].$reset()"
            />
            <VErrorMTS
              v-if="getErrors('firstname') && $v.form['firstname'].$dirty"
            >
              {{ getErrors('firstname') }}
            </VErrorMTS>
          </div>

          <div class="mts-application-form__field">
            <VInputMTS
              id="email"
              v-model="form.email"
              name="email"
              placeholder="Email"
              autocomplete="email"
              type="text"
              :class="{ error: getErrors('email') && $v.form['email'].$dirty }"
              @focus="$v.form['email'].$reset()"
            />
            <VErrorMTS v-if="getErrors('email') && $v.form['email'].$dirty">
              {{ getErrors('email') }}
            </VErrorMTS>
          </div>

          <div class="mts-application-form__field">
            <VPhoneInputMTS
              v-model="form.phone"
              :class="{ error: getErrors('phone') && $v.form['phone'].$dirty }"
              @focus="$v.form['phone'].$reset()"
            />
            <VErrorMTS v-if="getErrors('phone') && $v.form['phone'].$dirty">
              {{ getErrors('phone') }}
            </VErrorMTS>
          </div>
        </div>

        <VButtonMTS
          type="submit"
          class="mts-application-form__btn --l"
          :class="formBtnColor"
        >
          {{ formBtnText }}
        </VButtonMTS>

        <div v-if="formShowInfo" class="mts-application-form__info">
          Нажимая на кнопку «Оставить заявку», вы принимаете условия
          <a target="_blank" href="/legal/license-agreement.pdf">оферты</a>,
          соглашаетесь
          <a target="_blank" href="/legal/private-agreement-soft.pdf"
            >на обработку персональных данных</a
          >
          и связь с вами способами, указанными в оферте, в целях исполнения
          условий оферты.
        </div>
      </form>
    </template>
    <MTSFormMessage
      v-if="formSubmissionResult"
      :type-message="statusFormText"
      :success="success"
    />
  </div>
</template>

<script>
import SubmitGatewayMixin from '~/mixins/SubmitGatewayMixin'
import EventBus from '~/mixins/EventBus'
import ValidationFields from '~/mixins/ValidationFields'
import VPhoneInputMTS from '~/components/Common/MTS/VPhoneInputMTS'
import VInputMTS from '~/components/Common/MTS/VInputMTS'
import VErrorMTS from '~/components/Common/MTS/VErrorMTS'
import VButtonMTS from '~/components/Common/MTS/VButtonMTS'
import MTSFormMessage from '@/components/Common/Form/MTSFormMessage'

export default {
  name: 'MTSApplicationUniversalForm',
  components: {
    MTSFormMessage,
    VInputMTS,
    VErrorMTS,
    VPhoneInputMTS,
    VButtonMTS,
  },
  mixins: [ValidationFields, SubmitGatewayMixin, EventBus],
  props: {
    formName: {
      type: String,
      default: '',
    },
    formUserComment: {
      type: String,
      default: '',
    },
    formTitle: {
      type: String,
      default: '',
    },
    formDescription: {
      type: String,
      default: '',
    },
    formIdValue: {
      type: String,
      default: '',
    },
    formBtnText: {
      type: String,
      default: 'Оставить заявку',
    },
    formShowInfo: {
      type: Boolean,
      default: true,
    },
    formBtnColor: {
      type: String,
      default: '--violet',
    },
  },
  data() {
    return {
      form: {
        firstname: '',
        email: '',
        phone: '',
      },
    }
  },
  computed: {
    formSubmissionResult() {
      return this.formName === this.idFormOnResult ? this.statusForm : false
    },
  },
}
</script>

<style lang="scss" scoped>
.mts-application-form {
  &__title {
    font-family: $font-mts-compact;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    margin-bottom: 16px;
  }

  &__description {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__form {
    display: flex;
    flex-direction: column;

    &-fields {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 16px;
    }
  }

  &__btn {
    color: $color-white;
    margin: 20px auto 16px;
  }

  &__info {
    color: $color-gray-1;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    margin: 0 auto;
    text-align: center;

    a {
      text-decoration: none;
      color: inherit;
      border-bottom: 1px solid $color-gray-1;

      &:hover {
        border-bottom: none;
      }
    }
  }
}

.loading button.mts-application-form__btn {
  background-image: url('~/assets/img/loader-mts.svg');
  background-color: rgba(188, 195, 208, 0.5);
  background-size: 7%;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
}
</style>
